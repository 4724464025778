import tippy from "tippy.js";

const getHeaderHeight = () => {
  const headerEl = document.querySelector("header");

  if (!headerEl) {
    return 0;
  }

  return Math.ceil(headerEl.clientHeight);
};

domReady(() => {
  const headerHeight = getHeaderHeight();

  if (headerHeight && headerHeight > 0) {
    document.documentElement.style.setProperty(
      "--header-height",
      `${headerHeight}px`
    );
  }

  tippy("[data-sub-menu]", {
    content: (reference) => {
      const submenu = reference.getAttribute("data-sub-menu");

      if (!submenu) {
        return "";
      }

      const target = document.querySelector(`[data-menu="${submenu}"]`);

      if (!target) {
        return "";
      }

      return target;
    },
    interactive: true,
    placement: "bottom",
    maxWidth: "none",
    zIndex: 105,
    interactiveBorder: 4,
    offset: [0, 20],
    animation: "shift-away",
    hideOnClick: false,
    appendTo: document.querySelector("header [data-submenu-container]")!,
    popperOptions: {
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            padding: 0,
          },
        },
      ],
    },
  });

  tippy("header [data-language-menu-trigger]", {
    content: document.querySelector("header [data-language-menu]")!,
    placement: "bottom",
    interactive: true,
    animation: "shift-away",
    hideOnClick: false,
    offset: [0, 10],
  });

  const submenuToggles = document.querySelectorAll("[data-submenu-toggle]");

  submenuToggles.forEach((toggle) => {
    toggle.addEventListener("click", () => {
      console.log("CLICK!");
      toggle.ariaExpanded = toggle.ariaExpanded === "true" ? "false" : "true";
    });
  });

  // Add the mobile menu toggle functionality
  const menuToggleButton = document.querySelector("[data-menu-toggle]");
  const mobileMenu = document.querySelector("[data-mobile-menu]");

  if (menuToggleButton && mobileMenu) {
    menuToggleButton.addEventListener("click", () => {
      const isExpanded = mobileMenu.getAttribute("aria-expanded") === "true";
      menuToggleButton.setAttribute(
        "aria-expanded",
        isExpanded ? "false" : "true"
      );
      mobileMenu.setAttribute("aria-expanded", isExpanded ? "false" : "true");
    });
  }
});

function domReady(fn: () => void) {
  if (document.readyState === "loading") {
    document.addEventListener("DOMContentLoaded", fn);
  } else {
    fn();
  }
}
